import { Navigate, useLocation } from "react-router-dom";
import { useAuthProvider } from "Providers/AuthProvider";

const RequireAuthenticationV2 = ({ children }) => {
    
    let location = useLocation();
    
    const user = useAuthProvider();

    if (!user?.firebaseUser && !location.pathname.includes("/v2/login")) {
        return <Navigate to={"/v2/login"}/>
    }

    if (!user.firebaseUser?.emailVerified && !location.pathname.includes("/v2/verifyEmail") && !location.pathname.includes("/v2/authActions")) {
        return (
            <Navigate to={"/v2/verifyEmail"}/>
        )
    }
    
    return children;
}

export default RequireAuthenticationV2;
