import styles from './OnboardingAgreementTypePage.module.scss'
import AgreementTypeSelector from "../../../Features/Onboarding/Components/AgreementTypeSelector";
import DefaultPageLayout from 'Layouts/V2/DefaultPageLayout';
import Steps from "../../../Features/Onboarding/Components/Steps";
import {StyledText} from "../../../Components/V2/Text";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {useAgreement, useAgreementTerms, useSaveAgreementType} from "../../../Apis/AgreementApi";
import {AgreementType} from "../../../Apis/Models/AgreementModel";
import {useNavigate} from "react-router";
import CalendlyBooker from "../../../Features/Onboarding/Components/CalendlyBooker";
import {useAuthProvider} from "../../../Providers/AuthProvider";

const OnboardingAgreementTypePage = () => {

    const { t } = useTranslation();

    const { agreementId } = useParams();

    const { forceReload } = useAuthProvider();

    const navigate = useNavigate();

    const { data: agreementTerms } = useAgreementTerms();

    const { data: agreement, refetch } = useAgreement(agreementId!);
    const { mutateAsync, isLoading, isSuccess } = useSaveAgreementType()

    const onSelect = async (agreementType: AgreementType) => {
        await mutateAsync({...agreement!, agreementType: agreementType});

        await forceReload();
        await refetch();

        navigate(`/v2/${agreementId}/onboarding`);
    }

    return (
        <DefaultPageLayout contentMaxWidth={1440}>
            <div className={styles.content}>

                <div className={styles.headline}>
                    <Steps currentStep={2} totalSteps={2}/>

                    <StyledText type={"Headline"} weight={"Bold"} color={"secondary"}>
                        {t('onboarding.agreementType.headline')}
                    </StyledText>
                </div>

                <AgreementTypeSelector agreementTerms={agreementTerms ?? []} loadingState={isLoading ? 'Loading' : isSuccess ? 'Success' : 'None'} onSelect={onSelect}></AgreementTypeSelector>

                <CalendlyBooker />
            </div>

        </DefaultPageLayout>
    )

}

export default OnboardingAgreementTypePage