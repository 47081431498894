import {Route, Routes} from "react-router-dom";
import styles from "./NewRoutes.module.scss";
import LoginPage from "./LoginPage";
import VerifyEmailPage from "./VerifyEmailPage";
import DashboardRoutes from "./DashboardRoutes";
import InternationalContactPage from "./Onboarding/International/InternationalContactPage";
import InternationalContactPageSuccess from "./Onboarding/International/InternationalContactPageSuccess";
import RequireAuthenticationV2 from "../../Components/RequireAuthenticationV2";
import RedirectIfLoggedInV2 from "../../Components/RedirectIfLoggedInV2";
import ResetPasswordPage from "./ResetPasswordPage";
import AuthActionsPage from "./AuthActionsPage";
import OnboardingPage from "./OnboardingV2/OnboardingPage";

const NewRoutes = () => {

    return (
        <div className={styles.container}>
            <Routes>
                <Route path={"*"} element={(
                    <RedirectIfLoggedInV2>
                        <Routes>
                            <Route path="/onboarding/international/success" element={<InternationalContactPageSuccess />} />
                            <Route path="/onboarding/international" element={<InternationalContactPage />} />
                            <Route path="/onboarding" element={<OnboardingPage />} />
                            <Route path="/login" element={<LoginPage />} />
                            <Route path="/resetPassword" element={<ResetPasswordPage />} />
                        </Routes>
                    </RedirectIfLoggedInV2>
                )} />

                <Route path="/authActions" element={<AuthActionsPage />} />

                <Route path={"verifyEmail"} element={(
                    <RequireAuthenticationV2>
                        <VerifyEmailPage />
                    </RequireAuthenticationV2>
                )} />

                <Route path={"dashboard/*"} element={(
                    <RequireAuthenticationV2>
                        <DashboardRoutes />
                    </RequireAuthenticationV2>
                )} />


            </Routes>
        </div>
    )
}

export default NewRoutes