import styles from "./Button.module.scss";
import Checkmark from "Assets/Icons/V2/Checkmark";

interface Props {
    color: 'primary' | 'secondary';
    outline?: boolean;
    state?: 'None' | 'Loading' | 'Success';
    text: string;
    onClick?: (e: any) => void;
    disabled?: boolean;
    type?: "button" | "submit" | "reset";
    style?: any;
}
const Button = (props: Props) => {

    const { color, outline, state, disabled, text, onClick } = props;
    
    return (
        <button className={`${styles.button} ${styles[color]} ${outline ? styles.outline : ''}`} onClick={onClick} disabled={disabled} type={props.type} style={props.style}>
            <div className={`${styles.icon} ${outline ? styles.outline : ''} ${styles[color]} ${!!state && state !== 'None' ? styles.active : ''} ${state === 'Loading' ? styles.spin : ''}`}>
                {state === 'Loading' && (
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="transparent" xmlns="http://www.w3.org/2000/svg" >
                        <path fillRule="evenodd" clipRule="evenodd" d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM8 13.931C4.72438 13.931 2.06897 11.2756 2.06897 8C2.06897 4.72438 4.72438 2.06897 8 2.06897C11.2756 2.06897 13.931 4.72438 13.931 8C13.931 11.2756 11.2756 13.931 8 13.931Z" fill="#E0E0E0"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M15.6083 5.52786C14.5373 2.23168 11.4656 0 7.99981 0C7.42849 0 6.96533 0.463154 6.96533 1.03448C6.96533 1.60581 7.42849 2.06897 7.99981 2.06897C10.5693 2.06897 12.8466 3.72349 13.6406 6.16721C13.8171 6.71058 14.4007 7.00794 14.9441 6.83139C15.4875 6.65484 15.7848 6.07123 15.6083 5.52786Z"  className={`${styles.spinner} ${styles[color]}`} />
                    </svg>
                )}
                {state === 'Success' && (
                    <Checkmark />
                )}
            </div>
            {text}
        </button>
    )

}

Button.defaultProps = {
    color: 'primary',
    outline: false,
    type: 'button'
}

export default Button