const CaretIcon = (props: any) => {

    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path fillRule="evenodd" clipRule="evenodd" d="M9.00096 12C8.82572 12.0003 8.65589 11.9393 8.52096 11.8275L4.02096 8.07748H4.02096C3.70201 7.81238 3.65836 7.33892 3.92346 7.01998C4.18855 6.70104 4.66201 6.65738 4.98096 6.92248V6.92248L9.00096 10.2825L13.021 7.04248V7.04248C13.3425 6.78136 13.8148 6.83034 14.076 7.15189C14.0768 7.15292 14.0776 7.15395 14.0785 7.15498C14.354 7.46429 14.3265 7.93838 14.0172 8.21387C14.0031 8.22645 13.9885 8.2385 13.9735 8.24998L9.47346 11.8725C9.33464 11.9666 9.16828 12.0115 9.00096 12L9.00096 12Z" fill="#616161"/>
        </svg>
    )

}

export default CaretIcon