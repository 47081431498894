import {AgreementModel} from "../../../Apis/Models/AgreementModel";
import {ReactElement, useState} from "react";
import React from 'react';
import Modal from "../../../Components/V2/Modal";
import {useTranslation} from "react-i18next";
import Button from "Components/V2/Button";
import {
    useCurrentAgreementTerms, useSendContract,
    useSignContract
} from "../../../Apis/AgreementApi";
import styles from './AgreementReviewModal.module.scss';
import Gap from "Components/V2/Gap";
import useToast from "Hooks/useToast";
import {ControlledInput} from "../../../Components/V2/Input";
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router";
import Grid from "Components/V2/Grid";

type Props = {
    agreement: AgreementModel;
    trigger: ReactElement;
}
const AgreementReviewModal = ({ agreement, trigger}: Props) => {

    const { t } = useTranslation();

    const navigate = useNavigate();

    const { showSuccessToast } = useToast();

    const [visible, setVisible] = useState<boolean>(false);

    const { data: agreementTerms } = useCurrentAgreementTerms(agreement.id);
    const { mutateAsync: signContractMutation, isLoading, isSuccess } = useSignContract();
    const { mutateAsync: sendContractMutation, isLoading: isSendingContract } = useSendContract();

    const { control, handleSubmit, reset: resetSendEmailForm } = useForm<{email: string}>()
    const [showSendEmailField, setShowSendEmailField] = useState<boolean>(false);

    const sendEmail = async (data) => {
        await sendContractMutation({
            receiverEmail: data.email,
            agreementId: agreement.id!
        })

        setShowSendEmailField(false);

        showSuccessToast(t('agreement:agreementSent'));

        resetSendEmailForm()
    }

    const signContract = async () => {
        await signContractMutation({agreementId: agreement!.id, agreementTermsId: agreementTerms!.id});

        showSuccessToast(t('onboarding.agreementSigned'));

        setTimeout(() => {
            navigate(`/v2/dashboard`);
        }, 2000)
    }

    return (
        <>
            {React.cloneElement(trigger, {onClick: () => setVisible(true)})}

            <Modal visible={visible} title={t('agreement:agreementReview')} onCancel={() => setVisible(false)}>
                <Grid gridTemplateColumns={"1fr"} rowGap={16}>
                    {!!agreement && !!agreementTerms && (
                        <div className={styles.agreementArea}>
                            {agreementTerms?.text}
                        </div>
                    )}

                    <form onSubmit={handleSubmit(sendEmail)}>
                        <Gap size={"small"} className={"flex space-between"} style={{height: 48}}>
                            <div style={{flex: 1}}>
                                {showSendEmailField && (
                                    <ControlledInput placeholder={t('enterTheEmail')} name={"email"} control={control} />
                                )}
                            </div>

                            {showSendEmailField ? (
                                <Button outline text={t('send')} state={isSendingContract ? "Loading" : "None"} type={"submit"} />
                            ) : (
                                <Button outline text={t('receiveOnMail')} type={"button"} onClick={(e) => {
                                    e.preventDefault();
                                    setShowSendEmailField(true)
                                }} />
                            )}

                            <Button outline text={t('back')} />
                            <Button text={t('sign')} type={"button"} onClick={signContract} state={isLoading ? 'Loading' : isSuccess ? 'Success' : 'None'} />
                        </Gap>
                    </form>
                </Grid>
            </Modal>
        </>
    )

}

export default AgreementReviewModal