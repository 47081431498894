import AgreementTypeBox from "./AgreementTypeBox";
import CircledDashedIcon from "../../../Assets/Icons/CircledDashedIcon";
import PieChartIconV2 from "../../../Assets/Icons/PieChartIconV2";
import {useTranslation} from "react-i18next";
import styles from "./AgreementTypeSelector.module.scss";
import { StyledText } from "Components/V2/Text";
import {AgreementTermsModel} from "../../../Apis/Models/AgreementTermsModel";
import {AgreementType} from "../../../Apis/Models/AgreementModel";
import {useState} from "react";

interface Props {
    onSelect: (agreementType: 'Match' | 'Custom' | 'MatchAndCustom') => void;
    agreementTerms: AgreementTermsModel[];
    loadingState: 'None' | 'Loading' | 'Success';
}
const AgreementTypeSelector = (props: Props) => {

    const { t } = useTranslation();

    const [clickedButton, setClickedButton] = useState<'Match' | 'Custom' | 'MatchAndCustom'>()

    const getPrice = (agreementType: AgreementType) => {
        return props.agreementTerms.find(x => x.defaultTerms && x.agreementType === agreementType)?.unitSalesPrice;
    }

    const select = (agreementType: 'Match' | 'Custom' | 'MatchAndCustom') => {
        setClickedButton(agreementType);
        props.onSelect(agreementType);
    }
    
    return (
        <div className={styles.container}>
            <div className={styles.grid}>
                <AgreementTypeBox
                    showTopPick
                    icon={<CircledDashedIcon />}
                    headline={t('onboarding.matchUsage')}
                    subheadline={t('onboarding.matchUsageSubheadline')}
                    explainerHeadline={t('onboarding.matchUsageExplainerHeadline')}
                    explainerItems={[
                        t('onboarding.matchUsageExplainerItem1'),
                        t('onboarding.matchUsageExplainerItem2'),
                    ]}
                    price={`${getPrice('Match')} ${t('onboarding.priceExplainer')}`}
                    onClick={() => select('Match')}
                    buttonState={props.loadingState}
                    isClicked={clickedButton === 'Match'}
                />

                <AgreementTypeBox
                    icon={<PieChartIconV2 />}
                    headline={t('onboarding.customUsage')}
                    subheadline={t('onboarding.customUsageSubheadline')}
                    explainerHeadline={t('onboarding.customUsageExplainerHeadline')}
                    explainerItems={[
                        t('onboarding.customUsageExplainerItem1'),
                    ]}
                    price={`${getPrice('Match')} ${t('onboarding.priceExplainer')}`}
                    onClick={() => select('Custom')}
                    buttonState={props.loadingState}
                    isClicked={clickedButton === 'Custom'}
                />
            </div>

            <button style={{all: 'unset'}} disabled={props.loadingState !== 'None'} onClick={() => select('MatchAndCustom')}>
                <StyledText type={"Body"} color={"secondary"} weight={"Bold"}>
                    {t('onboarding.matchInternationalQuestion')}
                </StyledText>
            </button>
        </div>
    )

}

export default AgreementTypeSelector