import {Route, Routes} from "react-router-dom";
import AgreementRedirectPage from "./AgreementRedirectPage";
import AgreementDashboardPage from "./Dashboard/AgreementDashboardPage";
import AgreementOnboardingPage from "./Dashboard/AgreementOnboardingPage";
import AgreementReviewPage from "./Dashboard/AgreementReviewPage";
import InvoicesPage from "./Dashboard/InvoicesPage";
import NavigationItem from "../../Layouts/V2/NavigationItem";
import DashboardIcon from "../../Assets/Icons/V2/DashboardIcon";
import AgreementIcon from "../../Assets/Icons/V2/AgreementIcon";
import DefaultPageLayout from "Layouts/V2/DefaultPageLayout";
import AnnualReportsPage from "./Dashboard/AnnualReportsPage";
import {useTranslation} from "react-i18next";
import AgreementOnboardingEloverblikPage from "./Dashboard/AgreementOnboardingEloverblikPage";
import AgreementDetailsPage from "./Dashboard/AgreementDetailsPage";
import MenuMoreDropdown from "../../Layouts/V2/MenuMoreDropdown";
import ContactInfoPage from "./Dashboard/ContactInfoPage";
import ChangePasswordPage from "./Dashboard/ChangePasswordPage";
import AcceptedTermsPage from "./Dashboard/AcceptedTermsPage";
import InvoiceIcon from "../../Assets/Icons/V2/InvoiceIcon";
import ReportIcon from "../../Assets/Icons/V2/ReportIcon";
import {useEffect} from "react";
import {useAuthProvider} from "../../Providers/AuthProvider";
import {useLocation, useNavigate} from "react-router";
import OnboardingAgreementTypePage from "./Dashboard/OnboardingAgreementTypePage";
import Grid from "Components/V2/Grid";

const DashboardRoutes = () => {

    const { t } = useTranslation();

    const navigate = useNavigate();

    const location = useLocation();

    const { userContext } = useAuthProvider();

    useEffect(() => {
        if (!!userContext && userContext.agreements.length === 1 && userContext.agreements[0].agreementStatus === "Pending") {
            const agreement = userContext.agreements[0];

            if (location.pathname.includes("onboarding")) {
                return;
            }

            if (!agreement.agreementType) {
                navigate(`/v2/dashboard/${userContext.agreements[0].id}/onboarding/agreementType`);
            } else {
                navigate(`/v2/dashboard/${userContext.agreements[0].id}/onboarding`);
            }
        }
    }, [location, navigate, userContext]);

    const dashboardPage = (content: any) => (
        <DefaultPageLayout contentMaxWidth={1440} menuContent={(
            <>
                <NavigationItem text={t('dashboard')}  icon={<DashboardIcon />} isExact includeSearch route={`/v2/dashboard`} />
                <NavigationItem text={t('acceptedTerms')} icon={<ReportIcon />} includeSearch route={`/v2/dashboard/acceptedTerms`} />
                <NavigationItem text={t('invoices')} icon={<InvoiceIcon />} includeSearch route={`/v2/dashboard/invoices`} />
                <NavigationItem text={t('agreement')} icon={<AgreementIcon />} includeSearch route={`/v2/dashboard/details`} />
                {/**
                    <NavigationItem text={t('annualReports')} icon={<ReportIcon />} route={`/v2/dashboard/annualReports`} />
                 */}
                <MenuMoreDropdown />
            </>
        )}>
            <Grid gridTemplateColumns={"1fr"} rowGap={48}>
                {content}
            </Grid>
        </DefaultPageLayout>
    )

    return (
        <Routes>
            <Route path=":agreementId/onboarding/eloverblik" element={<AgreementOnboardingEloverblikPage />} />
            <Route path=":agreementId/onboarding/agreementType" element={<OnboardingAgreementTypePage />} />
            <Route path=":agreementId/onboarding" element={<AgreementOnboardingPage />} />

            <Route path=":agreementId/review" element={<AgreementReviewPage />} />

            <Route path="invoices" element={dashboardPage(<InvoicesPage />)} />
            <Route path="acceptedTerms" element={dashboardPage(<AcceptedTermsPage />)} />
            <Route path="annualReports" element={dashboardPage(<AnnualReportsPage />)} />

            <Route path="details" element={dashboardPage(<AgreementDetailsPage />)} />

            <Route path="changePassword" element={dashboardPage(<ChangePasswordPage />)} />
            <Route path="contactInfo" element={dashboardPage(<ContactInfoPage />)} />

            <Route path="/" element={dashboardPage(<AgreementDashboardPage />)} />
            
            <Route path="*" element={<AgreementRedirectPage />} />
        </Routes>
    )
}

export default DashboardRoutes