import { useNavigate, useParams } from "react-router-dom";
import { useAgreement } from "Apis/AgreementApi";
import { useForm } from "react-hook-form";
import Input from "Components/Input";
import { useTranslation } from "react-i18next";
import styles from './CustomUsagePage.module.scss';
import Button from "Components/Button";
import FlexRow from "Components/FlexRow";
import Space from "Components/Space";
import { useSetCustomUsage } from "Apis/CustomUsageApi";
import Step from "Components/Step";
import CenteredPageLayout from "Layouts/CenteredPageLayout";
import React, {useEffect, useState} from "react";

interface FormFields {
    yearlyUsage: number;
}

const CustomUsagePage = () => {
    
    const { t } = useTranslation();
    
    const navigate = useNavigate();
    
    const { agreementId } = useParams();
    
    const { data: agreement } = useAgreement(agreementId!);
    const { isLoading: isSavingCustomUsage } = useSetCustomUsage(agreement?.id!);
    
    const { control, handleSubmit, formState: { errors }, setError } = useForm<FormFields>()

    const [unitType, setUnitType] = useState<string>('MWH');

    useEffect(() => {
        /*if (!!agreement && !!agreement.currentAgreementUsage?.yearlyUsage) {
            navigate(`/agreements/setup/${agreement.id}/result`);
            return;
        }
*/
        if (!!agreement && agreement.agreementStatus !== 'Pending') {
            navigate(`/agreements/${agreement.id}/dashboard`);
            return;
        }
    }, [agreement, navigate])
    
    const saveCustomUsage = async (data: FormFields) => {
        if (data.yearlyUsage % 1 !== 0) {
            setError("yearlyUsage", {type: "manual", message: t('agreement:setup.custom.yearlyUsageError')});
            return;
        }

        if (unitType === 'GWH') {
            data.yearlyUsage = data.yearlyUsage * 1000
        }
        
        //await saveCustomUsageMutation(data);
        
        navigate(`/agreements/setup/${agreementId}/result`);
    }
    
    return (
        <CenteredPageLayout maxWidth={600}>
            <Step currentStep={2} numberOfSteps={5} />
        
            <hr/>
        
            <div className={styles.header}>
                <h1 className={"text-align-center"}>{t('agreement:setup.custom.h1')}</h1>
            
                <p>
                    {t('agreement:newAgreement.explainer')}
                </p>
            </div>
            
            <form onSubmit={handleSubmit(saveCustomUsage)}>
                <Space size={"large"}>
                    <FlexRow style={{gap: 10}} align={"end"}>
                        <FlexRow direction={"column"} align={"start"} className={"w-100"}>
                            <Input label={`${t('agreement:setup.custom.yearlyUsageLabel')} ${unitType}`} min="1" step="1" type={"number"} placeholder={`${t('agreement:setup.custom.yearlyUsagePlaceholder')} ${unitType}`} name={"yearlyUsage"} control={control} errors={errors.yearlyUsage} rules={{required: true}} />
                        </FlexRow>
                        <select className={styles.select} value={unitType} onChange={e => setUnitType(e.currentTarget.value)}>
                            <option value={'MWH'}>MWh</option>
                            <option value={'GWH'}>GWh</option>
                        </select>
                    </FlexRow>
     
                    <FlexRow justify={"center"} style={{paddingTop: 40}}>
                        <Button type={"submit"} loading={isSavingCustomUsage} disabled={isSavingCustomUsage}>{t('agreement:setup.custom.next')}</Button>
                    </FlexRow>
                </Space>
            </form>
        </CenteredPageLayout>
    )
}

export default CustomUsagePage
