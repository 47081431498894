import DefaultPageLayout from "../../../../Layouts/V2/DefaultPageLayout";
import styles from "./InternationalContactPage.module.scss";
import {StyledText} from "../../../../Components/V2/Text";
import Box from "../../../../Components/V2/Box";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Gap from "Components/V2/Gap";
import {PopupButton, useCalendlyEventListener} from "react-calendly";
import FlexRow from "../../../../Components/FlexRow";
import config from "Config";
import {useSearchParams} from "react-router-dom";
import {useCalendylyItem} from "../../../../Apis/CalendlyApi";
import moment from "moment";

const INTERNATIONAL_EVENT_SCHEDULED = 'INTERNATIONAL_EVENT_SCHEDULED';

const InternationalContactPageSuccess = () => {

    const { t } = useTranslation();

    const [searchParams ] = useSearchParams();
    const [calendlyEventUri, setCalendlyEventUri] = useState<string>('');

    const { mutateAsync: getCalendlyItem } = useCalendylyItem();
    const [calendlyItem, setCalendlyItem] = useState<any>(null)

    useCalendlyEventListener({
        onEventScheduled: (e) => {
            localStorage.setItem(INTERNATIONAL_EVENT_SCHEDULED, e.data.payload.event.uri);
            setCalendlyEventUri(e.data.payload.event.uri);
        }
    });

    useEffect(() => {
        let savedUri = localStorage.getItem(INTERNATIONAL_EVENT_SCHEDULED);
        setCalendlyEventUri(savedUri ?? '');

        if (!!savedUri) {
            getCalendlyItem(savedUri).then(e => {
                if (e.data.resource.status !== "active") {
                    localStorage.removeItem(INTERNATIONAL_EVENT_SCHEDULED)
                    setCalendlyEventUri('')
                } else {
                    setCalendlyItem(e);
                }
            });
        }
    }, [getCalendlyItem, setCalendlyItem, calendlyEventUri]);

    return (
        <DefaultPageLayout contentMaxWidth={1440}>
            <div className={styles.container}>

                <div className={styles.headline}>
                    <StyledText type={"Headline"} weight={"Bold"} color={"secondary"}>
                        {t('thanks')}
                    </StyledText>
                </div>

                <Box style={{maxWidth: 850, width: '100%'}}>
                    <Gap size={"small"} direction={"vertical"} style={{display: 'flex', alignItems: 'center'}}>
                        <StyledText type={"Body"} weight={"Bold"} color={"secondary"}>
                            {t('onboarding.international.requestReceived')}
                        </StyledText>

                        <StyledText type={"Caption"}>
                            {t('onboarding.international.willContactSoon')}
                        </StyledText>
                    </Gap>
                </Box>

                <Box type={"info"} size={"small"} style={{width: '100%'}}>
                    <FlexRow justify={"space-between"}>
                        <Gap size={"medium"}>
                            <img src="/Images/Resources/thomas.png" alt="Thomas" />
                            <FlexRow direction={"column"} align={"start"} style={{maxWidth: 220}}>
                                <StyledText type={"Body"} weight={"Bold"} color={"secondary"}>
                                    {!!calendlyItem && (
                                        t('youHaveAConversation')
                                    )}
                                    {!calendlyItem && (
                                        t('weAreHereToHelp')
                                    )}
                                </StyledText>
                                <StyledText type={"Caption"}>
                                    {!!calendlyItem && (
                                        <>
                                            {t('lookingForwardToMeetingYou')} <span style={{textDecoration: 'underline', fontWeight: 'bold'}}>{moment(calendlyItem.data.resource.start_time).format('dddd [d.] DD.MM [kl.] hh:mm')}</span>
                                        </>
                                    )}
                                    {!calendlyItem  && t('weAreHereToHelpSub')}
                                </StyledText>
                            </FlexRow>
                        </Gap>
                        <PopupButton
                            url={config.calendly.url}
                            rootElement={document.getElementById("root")!}
                            text={!!calendlyEventUri ? t('meetingBooked') : t('bookConversation')}
                            className={"calendlyOutlineButton"}
                            prefill={{
                                name: searchParams?.get('companyName') ?? '',
                                email: searchParams?.get('email') ?? '',
                                firstName: searchParams?.get('firstName') ?? '',
                                lastName: searchParams?.get('lastName') ?? '',
                                smsReminderNumber: searchParams?.get('phoneNumber') ?? '',
                            }}
                        />
                    </FlexRow>
                </Box>
            </div>
        </DefaultPageLayout>
    )

}

export default InternationalContactPageSuccess