import {useMutation, useQueryClient} from "react-query";
import { request } from "Apis/BaseApi";
import {AGREEMENT_CACHE_KEY, AGREEMENT_ESTIMATE_CACHE_KEY} from "./AgreementApi";

export const useSetCustomUsage = (agreementId: string) => {
    const queryClient = useQueryClient();

    return useMutation(async (data: {yearlyUsage: number, usageUnit: string}) => {
        return await request({
            url: `/CustomAgreementUsage/${agreementId}`,
            method: 'POST',
            data
        })
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(AGREEMENT_CACHE_KEY);
            queryClient.invalidateQueries(AGREEMENT_ESTIMATE_CACHE_KEY)
        }
    });
}
