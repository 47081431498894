import styles from "./Text.module.scss";
import {CSSProperties} from "react";

interface Props {
    children: any;
    type: 'Headline' | 'Lead' | 'Body' | 'Caption' | 'Small' | 'XSmall' | 'ButtonLarge' | 'Button';
    weight?: 'Bold' | 'Regular';
    color?: 'primary' | 'secondary' | 'white' | 'muted' | 'default' | 'error';
    style?: CSSProperties;
    tag?: 'span' | 'div' | 'label'
}

export const StyledText = (props: Props) => {
    
    let classNames = `${styles[props.type.toLowerCase()]} ${styles[props.weight!.toLowerCase()]} ${styles[props.color!]}`;
    let style = props.style;
    
    switch (props.tag) {
        case "div": 
            return <div className={classNames} style={style}>{props.children}</div>
        case "label":
            return <label className={classNames} style={style}>{props.children}</label>
        case "span":
        default:
            return <span className={classNames} style={style}>{props.children}</span>
    }
}
StyledText.defaultProps = {
    weight: 'Regular',
    color: 'default',
    tag: 'span'
}