const AuthorizationIcon = () => {

    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M15.7 22.0002H18V21.9992C19.65 21.9992 21 20.6492 21 18.9992V8C21 7.99972 21 7.99944 21 7.99916C21 7.98158 20.9995 7.96404 20.9986 7.94656C20.993 7.84036 20.9706 7.73643 20.9338 7.63913C20.8838 7.50653 20.8071 7.38622 20.71 7.28916L15.71 2.28916C15.52 2.09916 15.26 1.98916 15 1.99916V2C14.9938 2 14.9875 2.00005 14.9813 2.00016H8C6.34 2.00016 5 3.34016 5 5.00016V12.1002C5 12.6502 5.44 13.1002 6 13.1002C6.55 13.1002 7 12.6502 7 12.1002V5.00016C7 4.44016 7.44 4.00016 8 4.00016H14V7C14 8.1 14.89 9 16 9H19V19.0002C19 19.5502 18.55 20.0002 18 20.0002H15.7C15.14 20.0002 14.7 20.4402 14.7 21.0002C14.7 21.5502 15.14 22.0002 15.7 22.0002ZM17.5898 7H16V5.41016L17.5898 7ZM8.00048 22.0003H11.7505L11.7285 21.9893C12.5885 21.9893 13.3685 21.4393 13.6485 20.6193H13.6495L14.8595 17.5693C15.0595 16.9793 14.9895 16.3293 14.6495 15.8093C14.2395 15.2893 13.6295 14.9893 12.9795 14.9893V14.9903H11.9795V13.4803C11.9495 12.8303 11.6095 12.2203 11.0795 11.8503C10.1295 11.1803 8.80948 11.4203 8.14948 12.3803H8.15048L6.41561 15.0433C6.28143 15.0149 6.14239 15 6 15H5C3.89 15 3 15.89 3 17V20C3 21.1 3.89 22 5 22H6C6.35784 22 6.6945 21.9048 6.98591 21.7384C7.28625 21.9089 7.63376 22.004 8.00048 22.0003ZM5.99948 17V20H5V17H5.99948ZM8.00048 19.9993C8.01048 19.9093 8.01048 19.8193 8.00048 19.7393V16.2603L9.89048 13.4003C9.94048 13.3903 9.99048 13.4403 10.0005 13.5003V15.9993C10.0005 16.5493 10.4405 16.9993 11.0005 16.9993H12.9405L11.7505 19.9993H8.00048Z"
                  fill="#B386CF"/>
        </svg>

    )

}

export default AuthorizationIcon