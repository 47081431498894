import { useState } from "react";
import { useTranslation } from "react-i18next";

/*
const lowerCaseAlphabet = 'abcdefghijklmnopqrstuvwxyzæøå'.split('');
const upperCaseAlphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZÆØÅ'.split('');
const specialChars = '!"#¤%&/()=?`´^¨*\'-_.:,;<>|\\'.split('');
*/

interface PasswordCheck {
    message: string,
    fulfilled: boolean
}

export const usePasswordChecks = (verifyPasswordsAreEqual: boolean = true): [PasswordCheck[], (string) => void, (string) => void, (string) => void] => {
    
    const { t } = useTranslation();
    const [ firstPassword, setFirstPasswordInternal ] = useState<string>('');
    const [ secondPassword, setSecondPasswordInternal ] = useState<string>('');

    const setFirstPassword = (password: string) => {
        setFirstPasswordInternal(password);
        checkPasswordDouble(password, secondPassword);
    }

    const setSecondPassword = (password: string) => {
        setSecondPasswordInternal(password);
        checkPasswordDouble(firstPassword, password);
    }
    
    const [passwordChecks, setPasswordChecks] = useState<PasswordCheck[]>([]);
    
    const checkPassword = (password: string) => {
        const checks: PasswordCheck[] = []
        
        checks.push({
            message: t('auth:passwordChecks.length'),
            fulfilled: password.length >= 12
        })

        setPasswordChecks(checks)
    }

    const checkPasswordDouble = (firstPassword: string, secondPassword: string) => {
        const checks: PasswordCheck[] = []

        checks.push({
            message: t('auth:passwordChecks.length'),
            fulfilled: firstPassword.length >= 12
        })

        if (verifyPasswordsAreEqual) {
            checks.push({
                message: t('auth:passwordChecks.passwordsShouldMatch'),
                fulfilled: firstPassword === secondPassword
            })
        }

        setPasswordChecks(checks)
    }
    
    return [passwordChecks, checkPassword, setFirstPassword, setSecondPassword];
}
